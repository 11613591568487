export const GOOGLE_MAPS_API_KEY = "AIzaSyCTs_E7FfJIvoOU5tYxs9TjUK2tiECpVKk";
export const APP_NAME = "Qasr Al Awany";
export const COMPANY_NAME = "Qasr Al Awany";
export const HAS_ROBOTIC_BRANDING = true;
export const HAS_SMALL_ROBOTIC_BRANDING = false;
export const MENU_PACKAGE = "";
export const ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY =
    "KKmYEHDqqp1da4y9RUCMrWEG";
export const ONLINE_STORE_URL = "https://qasr.work";
export const SKIP_IMAGE_COMPRESSION = false;

export const STOCK_ENGINE = "SQL_AVG";
export const DISCOUNT_TYPE = "TAX_INCLUDED";

export const STRIPE_API_KEY = "";

export const THERMAL_RECEIPT_LOGO = { width: 277, height: 527, squareLogo: true };

export const CARD_PROCESSING_FEE_PERC = 0;
export const STRIPE_PROCESSING_FEE_PERC = 0;

export const TRANSLATE_LANG = ['EN', 'AR'];

export const INVOICE_ALT_PDF = "";

export const CRV_ACCOUNT_ID = -1;
export const VER_NO = "";
export const DEFAULT_LOCATION_ID = -1;

export const SHOULD_SEND_POS_RECEIPT_SMS = false;

export const WHATSAPP_TRACKER = "";

export const TIP_ENABLED = false;

export const VAT = {
    FRAC: 0.05,
    PERC: 5,
};
